// Load Styles
import * as bootstrap from 'bootstrap';
import '../scss/main.scss';
import '../scss/pelunasan.scss';
import { Article } from './article/article';
import { PelunasanComponents } from './components/pelunasan';
import { RegisterForm } from './register/otp';

window
// Your app code
const runApp = () => {
  try {
    const components = new PelunasanComponents();
    const regiterForm = new RegisterForm();
    const article = new Article();
    const newElement = document.createElement('div');
    newElement.innerHTML = components.add();
    document.body.appendChild(newElement);
    document.addEventListener('shown.bs.modal', (event: Event) => {
      const modalId = (event.target as HTMLElement).id;
      console.log(`Modal ID "${modalId}" is now open`);
    });
    // Check for support.
    if (!('indexedDB' in window)) {
      console.log("This browser doesn't support IndexedDB.");
      return;
    } else {
      console.log("This browser support IndexedDB.");
    }
    regiterForm.initSelectionModal();
    regiterForm.initForms();
    article.renderTopImage();
    let toast = document.getElementById('js-toast');
    if (toast) {
      var toastModal = bootstrap.Toast.getOrCreateInstance(toast);
      // toastModal.show();
    } else {
      console.log('bird');
    }
  } catch (error) {
    console.log(error);
  }
};
runApp();
