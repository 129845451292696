export class Article {
  article_key: string;
  constructor() {
    this.article_key = '5d439b6f0e812d366fbb8afc8c';
  }
  truncateString(str: string, num: number): string {
    if (str.length > num) {
      return str.substring(0, num).concat('...');
    } else {
      return 'Belum ada artikel';
    }
  }
  async renderTopImage(): Promise<void> {
    const catElement = document.getElementById('article-top');
    const camElement = document.getElementById('article-mid');
    const cabElement = document.getElementById('article-bot');
    if (catElement && camElement && cabElement) {
      try {
        const response = await fetch(`https://blog.pelunasan.id/ghost/api/v4/content/posts/?key=${this.article_key}&include=tags%2Cauthors&limit=all`);
        const articles = await response.json();
        const numArticle = articles.posts.length;
        if (numArticle > 0) {
          const numTop: number = Math.floor(Math.random() * numArticle);
          const numMid: number = Math.floor(Math.random() * numArticle);
          const numBottom: number = Math.floor(Math.random() * numArticle);
          const articleTop = articles.posts[numTop];
          const articleMid = articles.posts[numMid];
          const articleBot = articles.posts[numBottom];
          const catCard = catElement.children;
          const camCard = camElement.children;
          const cabCard = cabElement.children;
          for (var a = 0; a < catCard.length; a++) {
            const titleText = this.truncateString(articleTop['excerpt'], 64);
            const bodyText = this.truncateString(articleTop['excerpt'], 128);
            const imageUri = articleTop['feature_image'];
            const articleUri = articleTop['url'];

            const elementsTitle = catElement.getElementsByClassName('card-title');
            const elementsTitleArray = Array.from(elementsTitle);
            elementsTitleArray.forEach(element => {
              element.innerHTML = titleText;
            });

            const elementsText = catElement.getElementsByClassName('card-text');
            const elementsTextArray = Array.from(elementsText);
            elementsTextArray.forEach(element => {
              element.innerHTML = bodyText;
            });

            const elementsImage = catElement.getElementsByClassName('card-img');
            const elementsImageArray = Array.from(elementsImage);
            elementsImageArray.forEach((element: HTMLImageElement) => {
              element.src = imageUri;
            });

            const elementsUri = catElement.getElementsByClassName('link-with-right-chevron');
            const elementsUriArray = Array.from(elementsUri);
            elementsUriArray.forEach(element => {
              element.setAttribute('href', articleUri);
            });
          }
          for (var a = 0; a < camCard.length; a++) {
            const titleText = this.truncateString(articleMid['excerpt'], 32);
            const bodyText = this.truncateString(articleMid['excerpt'], 128);
            const imageUri = articleMid['feature_image'];
            const articleUri = articleMid['url'];

            const elementsTitle = camElement.getElementsByClassName('card-title');
            const elementsTitleArray = Array.from(elementsTitle);
            elementsTitleArray.forEach(element => {
              element.innerHTML = titleText;
            });

            const elementsText = camElement.getElementsByClassName('card-text');
            const elementsTextArray = Array.from(elementsText);
            elementsTextArray.forEach(element => {
              element.innerHTML = bodyText;
            });

            const elementsImage = camElement.getElementsByClassName('card-img');
            const elementsImageArray = Array.from(elementsImage);
            elementsImageArray.forEach((element: HTMLImageElement) => {
              element.src = imageUri;
            });

            const elementsUri = camElement.getElementsByClassName('link-with-right-chevron');
            const elementsUriArray = Array.from(elementsUri);
            elementsUriArray.forEach(element => {
              element.setAttribute('href', articleUri);
            });
          }
          for (var a = 0; a < cabCard.length; a++) {
            const titleText = this.truncateString(articleBot['excerpt'], 64);
            const bodyText = this.truncateString(articleBot['excerpt'], 128);
            const imageUri = articleBot['feature_image'];
            const articleUri = articleBot['url'];

            const elementsTitle = cabElement.getElementsByClassName('card-title');
            const elementsTitleArray = Array.from(elementsTitle);
            elementsTitleArray.forEach(element => {
              element.innerHTML = titleText;
            });

            const elementsText = cabElement.getElementsByClassName('card-text');
            const elementsTextArray = Array.from(elementsText);
            elementsTextArray.forEach(element => {
              element.innerHTML = bodyText;
            });

            const elementsImage = cabElement.getElementsByClassName('card-img');
            const elementsImageArray = Array.from(elementsImage);
            elementsImageArray.forEach((element: HTMLImageElement) => {
              element.src = imageUri;
            });

            const elementsUri = cabElement.getElementsByClassName('link-with-right-chevron');
            const elementsUriArray = Array.from(elementsUri);
            elementsUriArray.forEach(element => {
              element.setAttribute('href', articleUri);
            });
          }
          console.log(articles);
        }
      } catch (error) {
        console.log('Connection to server interrupted');
      }
    }
  }
}
