import { Modal } from "bootstrap";
import { PelunasanApi } from "../api/pelunasan";
export class RegisterForm {
  modalLogin: Modal;
  modalSelection: Modal;
  modalRegister: Modal;
  modalOtp: Modal;
  modalWaitingCallCs: Modal;
  tokenBasic: string;
  tokenBearer: string
  pin: string
  forgotPin: boolean;
  modalPinSet: Modal;
  mobile_number: string;
  otpCount: number;
  constructor() {
    this.modalSelection = new Modal(document.getElementById('modalSelection'));
    this.modalLogin = new Modal(document.getElementById('modalLoginForm'));
    this.modalRegister = new Modal(document.getElementById('modalRegisterForm'));
    this.modalOtp = new Modal(document.getElementById('modalOtp'));
    this.modalPinSet = new Modal(document.getElementById('modalSetPin'));
    this.modalWaitingCallCs = new Modal(document.getElementById('modalWaitingCallCs'));
    this.tokenBasic = 'R296aWxhOmxjTjN0UHF2aEFRSENJeA==';
    this.tokenBearer = '';
    this.pin = '';
    this.forgotPin = false;
    this.mobile_number;
    this.otpCount = 1;
  }
  initSelectionModal() {
    document.getElementById('js-version').innerHTML = 'v.1.00'
    const btnModalSelection = document.getElementById('btnModalSelection');
    btnModalSelection.addEventListener('click', () => {
      // Select all radio buttons with the specified name
      const radioButtons = document.querySelectorAll('input[name="radio-card"]');

      // Iterate through radio buttons to find the selected one
      let selectedValue = "";
      radioButtons.forEach((radioButton: HTMLInputElement) => {
        if (radioButton.checked) {
          selectedValue = radioButton.value;
        }
      });

      if (selectedValue !== "") {
        if (selectedValue === 'login') {
          this.modalSelection.hide();
          this.modalLogin.show();
        }
        if (selectedValue === 'register') {
          this.modalSelection.hide();
          this.modalRegister.show();
        }
      } else {
        alert("No radio button selected");
      }
    });
  }
  async initForms() {
    try {
      const forms = document.querySelectorAll('form.requires-validation');
      Array.from(forms).forEach((form) => {
        form.addEventListener(
          'submit',
          async (event) => {
            if (form instanceof HTMLFormElement) {
              if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
              } else {
                if (form.getAttribute('id') === 'registerForm') {
                  this.registerFormAction(form);
                }
                if (form.getAttribute('id') === 'loginForm') {
                  this.loginFormAction(form);
                }
                if (form.getAttribute('id') === 'pinForm') {
                  this.setPinAction(form);
                }
              }
            }
            form.classList.add('was-validated');
          },
          false
        );
      });
      document.getElementById('forgot-pin').onclick = () => {
        console.log('test');
      }
      document.getElementById('otp-retry').onclick = async () => {
        const objectResponse = await this.retryOtp();
        this.otpCount = this.otpCount + 1;
        document.getElementById('otp-counter').innerHTML = `${this.otpCount.toString()}/3`;
        if (this.otpCount === 3) {
          document.getElementById('otp-retry').remove();
        }
      }
      this.initOtpInput();
    } catch (error) {
      alert('error')
    }
  }
  private async retryOtp(): Promise<object> {
    try {
      const pelunasanApi = new PelunasanApi();
      let objResponse = await pelunasanApi.retryOtp(this.mobile_number, this.tokenBasic);
      return objResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  private async registerFormAction(form: any) {
    const pelunasanApi = new PelunasanApi();
    const formData = new FormData(form);
    const formDataObject: { [key: string]: string } = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value.toString();
      if (key === 'mobile_number') {
        formDataObject[key] = value.toString().replace(/^0/, '62');
        this.mobile_number = formDataObject[key];
      }
      if (key === 'pin') {
        this.pin = formDataObject[key];
      }      
    });
    event.preventDefault();
    event.stopPropagation();
    const objMobileCheck = <generalResponse>await pelunasanApi.mobileCheck(formDataObject['mobile_number'], this.tokenBasic);
    if (objMobileCheck.responseCode === '1000000') {
      this.modalRegister.hide();
      this.modalLogin.show();
    } else {
      let objectRegisterWithMobile = <generalResponse>await pelunasanApi.registerWithMobile(formDataObject, this.tokenBasic);
      if (objectRegisterWithMobile.responseCode === '1000000') {
        this.tokenBearer = objectRegisterWithMobile.token;
        this.modalRegister.hide();
        this.modalOtp.show();
        document.getElementById('otp-mobile-number').innerHTML = this.mobile_number;
      }
    }
  }
  private async loginFormAction(form: any) {
    const pelunasanApi = new PelunasanApi();
    const formData = new FormData(form);
    const formDataObject: { [key: string]: string } = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value.toString();
      if (key === 'mobile_number') {
        formDataObject[key] = value.toString().replace(/^0/, '62');
        this.mobile_number = formDataObject[key];
      }
    });
    event.preventDefault();
    event.stopPropagation();
    let objectLoginWithPin = <generalResponse>await pelunasanApi.loginWithMobile(formDataObject, this.tokenBasic);
    console.log(objectLoginWithPin);
    if (objectLoginWithPin.responseCode === '1000000') {
      this.tokenBearer = objectLoginWithPin.token;
      this.modalLogin.hide();
      const objProfile = await pelunasanApi.getUserprofile(this.tokenBearer);
      console.log(objProfile);
      this.modalWaitingCallCs.show();
    }
    if (objectLoginWithPin.responseCode === '4000005') {
      this.modalLogin.hide();
      this.modalRegister.show();
      // this.forgotPin = true;
      // let objectForgotPin = <generalResponse>await pelunasanApi.forgotPin(formDataObject, this.tokenBasic);
      // this.tokenBearer = objectForgotPin.token;
      // if (objectForgotPin.responseCode === '1000000') {
      //   this.modalOtp.show();
      // }
    }
  }
  private async setPinAction(form: any) {
    const pelunasanApi = new PelunasanApi();
    const formData = new FormData(form);
    const formDataObject: { [key: string]: string } = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value.toString();
      if (key === 'pin-first') {
        this.pin = formDataObject[key];
      }
    });
    event.preventDefault();
    event.stopPropagation();
    let objectLoginWithPin = <generalResponse>await pelunasanApi.userSetPin(this.pin, this.tokenBearer);
    if (objectLoginWithPin.responseCode === '1000000') {
      this.modalPinSet.hide();
      this.forgotPin = false;
      let loginObject = {
        mobile_number: this.mobile_number,
        pin: this.pin
      }
      let objectLoginWithPin = <generalResponse>await pelunasanApi.loginWithMobile(loginObject, this.tokenBasic);
      if (objectLoginWithPin.responseCode === '1000000') {
        this.tokenBearer = objectLoginWithPin.token;
        this.modalLogin.hide();
        const objProfile = await pelunasanApi.getUserprofile(this.tokenBearer);
        console.log(objProfile);
        this.modalWaitingCallCs.show();
      }
    }
  }
  private initOtpInput() {
    const inputs = document.querySelectorAll<HTMLInputElement>('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', function (event: KeyboardEvent) {
        if (event.key === "Backspace") {
          inputs[i].value = '';
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 95 && event.keyCode < 106) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();            
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    }
    const btnValidate = document.getElementById('btn-otp-validate');
    const spans = btnValidate.getElementsByTagName('span');
    btnValidate.addEventListener('click', async (event) => {
      event.preventDefault();
      const first = (<HTMLInputElement>document.getElementById('first')).value;
      const second = (<HTMLInputElement>document.getElementById('second')).value;
      const third = (<HTMLInputElement>document.getElementById('third')).value;
      const fourth = (<HTMLInputElement>document.getElementById('fourth')).value;
      const fifth = (<HTMLInputElement>document.getElementById('fifth')).value;
      const sixth = (<HTMLInputElement>document.getElementById('sixth')).value;
      const inputValue = first + second + third + fourth + fifth + sixth;
      if (inputValue.length < 6) {
        return;
      } else {
        for (let i = 0; i < spans.length; i++) {
          let isVisible = !spans[i].classList.contains('visually-hidden');
          if (isVisible) {
            spans[i].classList.add('visually-hidden');
          } else {
            spans[i].classList.remove('visually-hidden');
          }
        }
        const pelunasanApi = new PelunasanApi();
        let objOtpValidation = <generalResponse>await pelunasanApi.otpRegisterValidation(inputValue, this.tokenBearer);
        this.tokenBearer = objOtpValidation.token;
        if (objOtpValidation.responseCode === '1000000') {
          this.modalOtp.hide();
          if (this.forgotPin === true) {
            this.modalPinSet.show();
          } else {
            let objectPin = <generalResponse>await pelunasanApi.userSetPin(this.pin, this.tokenBearer);
            this.tokenBearer = objectPin.token;
            const objProfile = await pelunasanApi.getUserprofile(this.tokenBearer);
            console.log(objProfile);
            this.modalWaitingCallCs.show();
          }
          for (let i = 0; i < spans.length; i++) {
            let isVisible = !spans[i].classList.contains('visually-hidden');
            if (isVisible) {
              spans[i].classList.add('visually-hidden');
            } else {
              spans[i].classList.remove('visually-hidden');
            }
          }
        }
      }
    })
  }
}