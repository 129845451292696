export class PelunasanApi {
  constructor() {
  }
  async mobileCheck(mobile_number: string, tokenBasic: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/register/mobilenumber/check', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${tokenBasic}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          mobile_number: mobile_number
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Basic',
        token: tokenBasic
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Basic',
          token: tokenBasic
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async registerWithMobile(registerObject: any, tokenBasic: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/register/mobilenumber/otp', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${tokenBasic}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          mobile_number: registerObject['mobile_number'],
          full_name: registerObject['full_name'],
          email: registerObject['email']
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Basic',
        token: tokenBasic
      }
      if (response.status === 200) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: '1000000',
          responseMessage: 'Success',
          tokenType: objCause['authorization']['type'],
          token: objCause['authorization']['token']
        }
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Basic',
          token: tokenBasic
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async loginWithMobile(loginObject: any, tokenBasic: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/login/mobilenumber/pin', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${tokenBasic}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          mobile_number: loginObject['mobile_number'],
          pin: loginObject['pin']
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Basic',
        token: tokenBasic
      }
      if (response.status === 200) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: '1000000',
          responseMessage: 'Success',
          tokenType: objCause['authorization']['type'],
          token: objCause['authorization']['token']
        }
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Basic',
          token: tokenBasic
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async otpRegisterValidation(otp: string, tokenBearer: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/register/otp/validate', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${tokenBearer}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          otp: otp,
          user_type: 'WEB'
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Bearer',
        token: tokenBearer
      }
      if (response.status === 200) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: '1000000',
          responseMessage: 'Success',
          tokenType: objCause['authorization']['type'],
          token: objCause['authorization']['token']
        }
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Bearer',
          token: tokenBearer
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async userSetPin(pin: string, tokenBearer: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/user/setPin', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${tokenBearer}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          pin: pin
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Bearer',
        token: tokenBearer
      }
      if (response.status === 200) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: '1000000',
          responseMessage: 'Success',
          tokenType: objCause['authorization']['type'],
          token: objCause['authorization']['token']
        }
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Bearer',
          token: tokenBearer
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getUserprofile(tokenBearer: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/user/profile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${tokenBearer}`,
          'content-type': 'application/json;charset=UTF-8',
        }
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Bearer',
        token: tokenBearer
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Bearer',
          token: tokenBearer
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async forgotPin(loginObject: any, tokenBasic: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/login/mobilenumber/forgotpin', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${tokenBasic}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          mobile_number: loginObject['mobile_number']
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Basic',
        token: tokenBasic
      }
      if (response.status === 200) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: '1000000',
          responseMessage: 'Success',
          tokenType: objCause['authorization']['type'],
          token: objCause['authorization']['token']
        }
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Basic',
          token: tokenBasic
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
  async retryOtp(mobile_number: string, tokenBasic: string): Promise<object> {
    try {
      const response = await fetch('https://api.pelunasan.id/v1/retry/otp', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${tokenBasic}`,
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          mobile_number: mobile_number
        }),
      });
      let objectResponse: generalResponse = {
        responseCode: '1000000',
        responseMessage: 'Success',
        tokenType: 'Basic',
        token: tokenBasic
      }
      if (response.status === 200) {
        objectResponse = {
          responseCode: '1000000',
          responseMessage: 'Success',
          tokenType: 'Basic',
          token: tokenBasic
        }
      }
      if (response.status === 400) {
        let objCause = await response.json();
        objectResponse = {
          responseCode: objCause.cause['responseCode'],
          responseMessage: objCause.cause['responseMessage'],
          tokenType: 'Basic',
          token: tokenBasic
        }
      } return objectResponse;
    } catch (error) {
      throw new Error(error);
    }
  }
}